import React, { useState, useEffect, useContext, useCallback } from 'react';
import Navbar from "../components/Navbar";
import "../styles/game.css";
import Button from '@mui/material/Button';
import GameController from "@mui/icons-material/SportsEsports";
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableVirtuoso } from 'react-virtuoso';
import { BrowserView } from "react-device-detect";
import api from "../utils/api";
import { useParams } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup ? radioGroup.value === props.value : false;
  return <FormControlLabel checked={checked} {...props} />;
}

const Game = () => {
  const { gameId, playerToSearchFor } = useParams();
  const [selectedValue, setSelectedValue] = useState('all');
  const [game, setGame] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [scores, setScores] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [numericalMetrics, setNumericalMetrics] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [searchQuery, setSearchQuery] = useState(playerToSearchFor || ''); 

  const sortData = (data, sortBy, order) => {
    if (!sortBy) return data;
    return [...data].sort((a, b) => {
      const valueA = a.score ? a.score[sortBy] : a[sortBy];
      const valueB = b.score ? b.score[sortBy] : b[sortBy];
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return order === 'desc' ? valueB - valueA : valueA - valueB;
      }
      return 0;
    });
  };

  const fetchScores = useCallback(async (sortBy = null, order = 'desc', playerType = selectedValue) => {
    setIsLoading(true);
    try {
      const params = {};
      if (sortBy) {
        params.sort_by = sortBy;
        params.order = order;
      }
      if (playerType !== 'all') {
        params.player_type = playerType;
      }

      const response = await api.get(`/games/${gameId}/processed-scores/`, { params });
      const data = response.data;
      const sortedScores = sortData(data.scores, sortBy, order);
      setScores(sortedScores);

      if (data.scores.length > 0) {
        const sampleScore = data.scores[0].score || data.scores[0];
        const sampleMetrics = Object.keys(sampleScore);
        setMetrics(sampleMetrics);
        const numericalMetrics = sampleMetrics.filter(metric =>
          metric !== 'player_name' && metric !== 'player_type' && typeof (sampleScore.score ? sampleScore.score[metric] : sampleScore[metric]) === 'number'
        );
        setNumericalMetrics(numericalMetrics);
      }
    } catch (error) {
      console.error('Error fetching dynamic scores:', error);
    } finally {
      setIsLoading(false);
    }
  }, [gameId, selectedValue]);

  const handlePlayGameClick = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/game/session/initiate/${gameId}/`);
      if (response.data.redirect_url) {
        window.open(response.data.redirect_url, '');
      } else {
        console.error('Token or redirect URL is undefined.');
      }
    } catch (error) {
      console.error('Failed to initiate game session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = 'auto';
    };
  }, []);

  const handleRadioChange = (event) => {
    event.preventDefault();
    setSelectedValue(event.target.value);
  };

  const filteredScores = scores.filter(score => {
    if (selectedValue === 'all') return true;
    return score.player_type === selectedValue;
  }).filter(score => {
    return score.player_name?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const getGameInfo = useCallback(async () => {
    const link = '/game_from_id/' + gameId;
    let response;
    try {
      response = await api.get(link);
      setGame(response.data);
    } catch (error) {
      console.error('Error fetching game data:', error);
    } finally {
      if (response && response.data && response.data.message === 'game does not exist') {
        window.location.replace("/404");
      }
      else {
        setIsLoading(false);
      }
    }
  }, [gameId]);

  useEffect(() => {
    if (gameId) {
      fetchScores();
    }
  }, [gameId,fetchScores]);

  useEffect(() => {
    if (!game) {
      getGameInfo();
    }
  }, [gameId, fetchScores, getGameInfo,  playerToSearchFor, game],);

  const handleDownloadScores = async () => {
    try {
      const response = await api.get(`/research/data_download/${gameId}`);
      const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `game_${gameId}_raw_scores.json`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setShowAlert(true);
      } else {
        console.error('Error downloading scores:', error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const sortButtonStyles = {
    backgroundColor: "#2A548B",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    margin: "0 20px",
  };

  return (
    <div>
      <Navbar />
      <BrowserView>
        <img className={"bgImage"} id="topLeft" src={game.image} alt="Game Icon" />
        <img className={"bgImage"} id="bottomRight" src={game.image} alt="Game Icon" />
      </BrowserView>
      <div className={"gameInfo"}>
        <img id="gameIcon" src={game.image} alt="Game Icon" />
        <h1>{game.name}</h1>
        <p>{game.description}</p>
        <BrowserView>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#2A548B" }}
              endIcon={<GameController />}
              disabled={!user}
              onClick={handlePlayGameClick}
            >
              Play Game
            </Button>
          </div>
        </BrowserView>
        <RadioGroup row name="use-radio-group" defaultValue="all" onChange={handleRadioChange} style={{ marginBottom: "20px" }}>
          <MyFormControlLabel value="all" label="All" control={<Radio style={{ color: 'white' }} />} />
          <MyFormControlLabel value="AI" label="AI" control={<Radio style={{ color: 'white' }} />} />
          <MyFormControlLabel value="HU" label="Human" control={<Radio style={{ color: 'white' }} />} />
        </RadioGroup>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '40px' }}>
          <select onChange={(e) => {
            fetchScores(e.target.value, 'desc');
          }} defaultValue="" style={sortButtonStyles}>
            <option value="" disabled>Sort by...</option>
            {numericalMetrics.map((metric) => (
              <option key={metric} value={metric}>{metric}</option>))}
          </select>
          <div style={{ backgroundColor: "#2A548B" }}>

            <Button
              variant="contained"
              style={{ backgroundColor: "#2A548B" }}
              disabled={!user}
              onClick={handleDownloadScores}
            >
              Download Scores
            </Button>
            {showAlert && (
              <div className="custom-modal">
                <div className="custom-modal-content">
                  <p>You must have researcher privileges to download the raw JSON data.</p>
                  <button className="close-modal" onClick={() => setShowAlert(false)}>Close</button>
                </div>
              </div>
            )}

          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Search by Username"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              padding: '10px 10px',
              border: '5px solid #2A548B',
              borderRadius: '15px',
              fontSize: '16px',
              outline: 'none',
              transition: 'transform 0.3s ease, color 0.3s ease',
              backgroundColor: '#2A548B',
              color: 'white',
              marginRight: '10px'
            }}
          />
        </div>

        <div style={{ height: '400px', width: '100%' }}>
          <TableVirtuoso
            data={filteredScores}
            style={{ width: '100%' }}
            components={{
              Table: React.forwardRef(({ style, ...props }, ref) => (
                <Table ref={ref} {...props} style={{ ...style, backgroundColor: 'transparent' }} />
              )),
              TableRow: React.forwardRef(({ style, ...props }, ref) => (
                <TableRow ref={ref} {...props} style={{ ...style, color: 'white', borderColor: 'white', borderStyle: 'solid', borderWidth: 1, backgroundColor: 'transparent' }} />
              )),
              TableCell: React.forwardRef(({ style, ...props }, ref) => (
                <TableCell ref={ref} {...props} style={{ ...style, color: 'white', borderColor: 'white', borderStyle: 'solid', borderWidth: 1, textAlign: 'center', backgroundColor: 'transparent' }} />
              )),
            }}
            fixedHeaderContent={() => (
              <>
                <TableCell style={{ color: 'white', textAlign: 'center' }}>Player Name</TableCell>
                <TableCell style={{ color: 'white', textAlign: 'center' }}>Player Type</TableCell>
                {metrics.filter(metric => metric !== 'player_name' && metric !== 'player_type').map(metric => (
                  <TableCell key={metric} style={{ color: 'white', textAlign: 'center' }}>{metric}</TableCell>
                ))}
              </>
            )}

            itemContent={(index, scoreData) => {
              const score = scoreData.score || scoreData;
              return (
                <>
                  <TableCell style={{ textAlign: 'center' }}>{scoreData.player_name}</TableCell>
                  <TableCell style={{ textAlign: 'center' }}>{scoreData.player_type}</TableCell>
                  {metrics.filter(metric => metric !== 'player_name' && metric !== 'player_type').map(metric => (
                    <TableCell key={metric} style={{ textAlign: 'center' }}>
                      {score[metric] !== undefined ? score[metric].toString() : 'N/A'}
                    </TableCell>
                  ))}
                </>
              );
            }}
          />
        </div>

        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
        </div>
      </div>
    </div>
  );
};

export default Game;
