import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import AuthContext from '../context/AuthProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pt: 2,
  px: 4,
  pb: 3,
};

export function Login({ handleCloseUserMenu }) {

  let { user, logoutUser, loginUser, registerUser, loginError, passwordMismatch, usernameTaken, emailTaken } = useContext(AuthContext)
  const [open, setOpen] = React.useState(false);
  const [isInitialContent, setIsInitialContent] = React.useState(true);
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggleContent = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setPassword2('');
    setIsInitialContent((prevIsInitialContent) => !prevIsInitialContent);
  };

  const handleRegister = async e => {
    e.preventDefault();
    registerUser(e, email, username, password, password2);
  }

  const getContent = () => {
    return isInitialContent ? (
        <Box
          sx={style}
        >
          <Typography variant="h5" component="h1" sx={{ mb: -1, fontWeight: 'bold', color: '#274C6A' }}>
            Log In
          </Typography>
          <Box component="form" onSubmit={loginUser} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              error={loginError}
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              variant="outlined"
              value={username}
              onChange={e => setUsername(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              error={loginError}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"
              value={password}
              onChange={e => setPassword(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: '#274C6A', color: 'white', '&:hover': { bgcolor: '#1a3457' } }}
            >
              Log In
            </Button>
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2, color: '#555' }}>
          Don't have an account?{' '}
            <Link onClick={handleToggleContent} variant="body2" sx={{ color: '#274C6A' }}>
              Create one here
            </Link>
          </Typography>
        </Box>
    ) : (
        <Box
          sx={style}
        >
          <Typography variant="h5" component="h1" sx={{ mb: -1, fontWeight: 'bold', color: '#274C6A' }}>
            Sign Up
          </Typography>
          <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              variant="outlined"
              value={username}
              error={usernameTaken}
              onChange={e => setUsername(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              autoComplete="email"
              variant="outlined"
              value={email}
              error={emailTaken}
              onChange={e => setEmail(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"
              error={passwordMismatch}
              onChange={e => setPassword(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              error={passwordMismatch}
              onChange={e => setPassword2(e.target.value)}
              sx={{ input: { color: '#333' }, label: { color: '#555' } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: '#274C6A', color: 'white', '&:hover': { bgcolor: '#1a3457' } }}
            >
              Sign Up
            </Button>
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2, color: '#555' }}>
          Already have an account?{' '}
            <Link onClick={handleToggleContent} variant="body2" sx={{ color: '#274C6A' }}>
              Login here
            </Link>
          </Typography>
        </Box>
    );
  };
  
  return (
    <div>
      { !user ? (
        <MenuItem key="login" onClick={() => {
          handleOpen();
          handleCloseUserMenu();
        }}>
          <Typography color="black" textAlign="center">Login/Signup</Typography>
        </MenuItem>
      ) : (
        <MenuItem key="login" onClick={() => {
          logoutUser();
          handleCloseUserMenu();
        }}>
          <Typography color="black" textAlign="center">Logout</Typography>
        </MenuItem>
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        {getContent()}
      </Modal>
    </div>
  );
};