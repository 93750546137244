import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import api from '../utils/api.js';
import AuthContext from '../context/AuthProvider';

const fieldStyle = {
  mt: 2,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
      },
      '&:hover fieldset': {
        borderColor: '#1C9ACA',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      '& input': {
        color: '#FFFFFF',
      },
    },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  maxHeight: '95%',
  bgcolor: '#274C6A',
  overflow: 'scroll',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const CreateGame = ({ open, handleClose }) => {
  let { user } = useContext(AuthContext);
  let shouldReload = false;
  const [gameData, setGameData] = useState({
    name: '',
    description: '',
    image: null,
    link: '',
    evaluator: null,
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    const newValue = (name === 'image' || name === 'evaluator') ? files[0] : value;

    setGameData({ ...gameData, [name]: newValue });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('name', gameData.name);
      formData.append('description', gameData.description);
      formData.append('image', gameData.image);
      formData.append('link', gameData.link);
      formData.append('uploaded_by', user.user_id);
      formData.append('evaluation_script', gameData.evaluator);
      await api.post('/create_game/', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          key: JSON.parse(localStorage.getItem('authTokens')).access 
        }
      });
      shouldReload = true;
      handleClose(shouldReload);
    } catch (error) {
      console.error('Error creating game:', error);
    }
  };

  const justClose = () => {
    handleClose(shouldReload);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={justClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" fontWeight={800} component="h2">
            Create New Game
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Fill out the fields below to submit a new game.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Games will not appear on NeuroArcade until they have been approved by an administrator.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
            Note: If you are an administrator your games will be automatically approved.
          </Typography>
          <TextField
            label="Name"
            name="name"
            value={gameData.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={fieldStyle}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Description"
            name="description"
            value={gameData.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            sx={{
              ...fieldStyle,
              '& .MuiOutlinedInput-inputMultiline': {
                color: '#fff',
              },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
          />
          <TextField
            label="Link"
            name="link"
            value={gameData.link}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            sx={fieldStyle}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-image"
            type="file"
            name="image"
            onChange={handleChange}
          />
          <Stack direction={"row"} sx={{alignItems: "center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mr:2 }}>
              Image:
            </Typography>
            <label htmlFor="contained-button-image">
              <Button variant="contained" component="span" sx={{ mt: 2 }}>
                Upload Image
              </Button>
            </label>
          </Stack>
          <input
            accept=".py"
            style={{ display: 'none' }}
            id="contained-button-evaluator"
            type="file"
            name="evaluator"
            onChange={handleChange}
            value={gameData.evaluation_script}
          />
          <Stack direction={"row"} sx={{alignItems: "center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mr:2 }}>
              Evaluation Script:
            </Typography>
            <label htmlFor="contained-button-evaluator">
              <Button variant="contained" component="span" sx={{ mt: 2 }}>
                Upload .py
              </Button>
            </label>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#386b2f", color: "#FFFFFF", borderRadius: 12 }}
              onClick={handleSubmit}
            >
              Submit Game
            </Button>
            <Button
              variant="outlined"
              style={{ borderColor:"#1C9ACA",color:"#1C9ACA", borderRadius: 12}}
              onClick={justClose}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateGame;
