import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import api from '../utils/api.js';

const fieldStyle = {
  mt: 2,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
      },
      '&:hover fieldset': {
        borderColor: '#1C9ACA',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      '& input': {
        color: '#FFFFFF',
      },
    },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  bgcolor: '#274C6A',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const RejectionReasoning = ({ open, handleClose, gameId }) => {
  let shouldDeny = false;
  const [reason, setReason] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    setReason(value);
  };

  const handleSubmit = async () => {
    try {
      let link = '/update_game_from_id/' + gameId + '/'
      await api.put(link, {'rejection_reason': reason}, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          key: JSON.parse(localStorage.getItem('authTokens')).access 
        }
      });
      shouldDeny = true;
      handleClose(shouldDeny);
    } catch (error) {
      console.error('Error creating game:', error);
    }
  };

  const justClose = () => {
    handleClose(false);
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={justClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" fontWeight={800} component="h2">
            Provide Reason For Rejection
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
            Let the developer of this game know why you rejected it below, if they want to reapply they will have to read this first.
          </Typography>
          <TextField
            label="Reason"
            name="reason"
            value={reason}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            sx={{
              ...fieldStyle,
              '& .MuiOutlinedInput-inputMultiline': {
                color: '#fff',
              },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
          />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#386b2f", color: "#FFFFFF", borderRadius: 12 }}
              onClick={handleSubmit}
            >
              Submit Rejection
            </Button>
            <Button
              variant="outlined"
              style={{ borderColor:"#1C9ACA",color:"#1C9ACA", borderRadius: 12}}
              onClick={justClose}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default RejectionReasoning;
