import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from "@mui/material/Stack";
import api from '../utils/api.js';

const fieldStyle = {
  mt: 2,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
      },
      '&:hover fieldset': {
        borderColor: '#1C9ACA',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      '& input': {
        color: '#FFFFFF',
      },
    },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  bgcolor: '#274C6A',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const EditGame = ({ open, handleClose, gameId, isDenied }) => {
  let shouldReload = false;
  const [gameData, setGameData] = useState({});
  const [game, setGame] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let updatedGameData = { ...gameData };
    if (value.length > 0) {
      updatedGameData[name] = value;
    } else {
      delete updatedGameData[name];
    }
    setGameData(updatedGameData);
  };

  const handleSubmit = async () => {
    try {
      let link = '/update_game_from_id/' + gameId + '/'
      await api.put(link, (!isDenied) ? gameData : { ...gameData, 'status': 'pending' }, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          key: JSON.parse(localStorage.getItem('authTokens')).access 
        }
      });
      shouldReload = true;
      handleClose(shouldReload);
    } catch (error) {
      console.error('Error creating game:', error);
    }
  };

  const getGameInfo = async () => {
    const link = '/game_from_id/' + gameId
    let response;
    try {
      response = await api.get(link);
      setGame(response.data);
    } catch (error) {
      console.error('Error fetching game data:', error);
    } finally {
      if (response.data.message === 'game does not exist') {
        window.location.replace("/404");
      }
      else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      getGameInfo();
    }
    // eslint-disable-next-line
  }, [open]);

  const justClose = () => {
    handleClose(shouldReload);
  }

  if (isLoading) {
    return;
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={justClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" fontWeight={800} component="h2">
            Edit Game
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
            Fill out the fields you wish to change, no change will be applied to fields left blank
          </Typography>
          {isDenied ? (
            <div>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
              This game submission has been rejected by an admin for the following reason:
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb:2 }}>
              "{game.rejection_reason}"
            </Typography>
            </div>
          ) : ("")}
          <TextField
            label="Name"
            name="name"
            value={gameData.name || ''}
            onChange={handleChange}
            fullWidth
            placeholder={game.name}
            variant="outlined"
            sx={fieldStyle}
            InputLabelProps={{
              style: { color: '#fff' },
              shrink: true,
            }}
          />
          <TextField
            label="Description"
            name="description"
            value={gameData.description}
            onChange={handleChange}
            fullWidth
            placeholder={game.description}
            multiline
            rows={4}
            variant="outlined"
            sx={{
              ...fieldStyle,
              '& .MuiOutlinedInput-inputMultiline': {
                color: '#fff',
              },
            }}
            InputProps={{
              style: { color: '#fff' },
            }}
            InputLabelProps={{
              style: { color: '#fff' },
              shrink: true,
            }}
          />
          <TextField
            label="Link"
            name="link"
            value={gameData.link || ''}
            onChange={handleChange}
            fullWidth
            placeholder={game.link}
            variant="outlined"
            sx={fieldStyle}
            InputLabelProps={{
              style: { color: '#fff' },
              shrink: true,
            }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-image"
            type="file"
            name="image"
            onChange={handleChange}
          />
          <Stack direction={"row"} sx={{alignItems: "center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mr:2 }}>
              Image:
            </Typography>
            <label htmlFor="contained-button-image">
              <Button variant="contained" component="span" sx={{ mt: 2 }}>
                Upload Image
              </Button>
            </label>
          </Stack>
          <input
            accept=".py"
            style={{ display: 'none' }}
            id="contained-button-evaluator"
            type="file"
            name="evaluator"
            onChange={handleChange}
          />
          <Stack direction={"row"} sx={{alignItems: "center"}}>
            <Typography id="modal-modal-description" sx={{ mt: 2, mr:2 }}>
              Evaluation Script:
            </Typography>
            <label htmlFor="contained-button-evaluator">
              <Button variant="contained" component="span" sx={{ mt: 2 }}>
                Upload .py
              </Button>
            </label>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#386b2f", color: "#FFFFFF", borderRadius: 12 }}
              onClick={handleSubmit}
            >
              {isDenied ? "Resubmit Game" : "Confirm Changes"}
            </Button>
            <Button
              variant="outlined"
              style={{ borderColor:"#1C9ACA",color:"#1C9ACA", borderRadius: 12}}
              onClick={justClose}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EditGame;
