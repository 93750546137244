import React, {useEffect, useState, useContext} from 'react';
import Navbar from "../components/Navbar";
import "../styles/profile.css";
import api from "../utils/api";
import AuthContext from '../context/AuthProvider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import ListItemButton from '@mui/material/ListItemButton';
import RejectionReasoning from '../components/RejectionReasoning';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingGames, setPendingGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(-1);

  const openReasoning = (game_id) => {
    setSelectedGame(game_id);
    setOpen(true);
  }

  const handleClose = async (shouldDeny) => {
    setOpen(false);
    if (shouldDeny) {
      let link = '/update_game_from_id/' + selectedGame + '/'
      await api.put(link, {status: 'denied'}, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
      window.location.reload();
    }
  }

  const handleUserDelete = async (user_id) => {
    let link = '/delete_user_from_id/' + user_id
    try {
      await api.delete(link, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
      window.location.reload();
    } catch (error) {
      console.error('Error deleting user account:', error);
    }
  };

  const handleGameDelete = async (game_id) => {
    let link = '/delete_game_from_id/' + game_id
    try {
      await api.delete(link, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
      window.location.reload();
    } catch (error) {
      console.error('Error deleting game:', error);
    }
  };

  const handleApproveOrDenyUser = async (approved, user_id) => {
    let decision = approved ? 'researcher' : 'player'
    let link = '/update_user_from_id/' + user_id + '/'
    await api.put(link, {status: decision}, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
    window.location.reload();
  }

  const handleApproveOrDenyGame = async (approved, game_id) => {
    let decision = approved ? 'approved' : 'denied'
    if (decision === 'denied') {
      openReasoning(game_id);
    }
    else {
      let link = '/update_game_from_id/' + game_id + '/'
      await api.put(link, {status: decision}, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
      window.location.reload();
    }
  }

  function renderPURow(props) {
    const { index, style } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => {window.location.href = "/profile/"+pendingUsers[index].id}}>
          <ListItemText sx={{userSelect: "none", paddingLeft: "2px"}} primary={pendingUsers[index].name} />
        </ListItemButton>
          <IconButton align="right" aria-label="delete" onClick={() => {handleApproveOrDenyUser(true, pendingUsers[index].id)}}>
            <DoneIcon sx={{color: "lime"}} />
          </IconButton>
          <IconButton align="right" aria-label="delete" onClick={() => {handleApproveOrDenyUser(false, pendingUsers[index].id)}}>
            <CloseIcon sx={{color: "red"}} />
          </IconButton>
      </ListItem>
    );
  }

  function renderPGRow(props) {
    const { index, style } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => {window.location.href = "/game/"+pendingGames[index].id}}>
          <ListItemText sx={{userSelect: "none", paddingLeft: "2px"}} primary={pendingGames[index].name} />
        </ListItemButton>
          <IconButton align="right" aria-label="delete" onClick={() => {handleApproveOrDenyGame(true, pendingGames[index].id)}}>
            <DoneIcon sx={{color: "lime"}} />
          </IconButton>
          <IconButton align="right" aria-label="delete" onClick={() => {handleApproveOrDenyGame(false, pendingGames[index].id)}}>
            <CloseIcon sx={{color: "red"}} />
          </IconButton>
      </ListItem>
    );
  }

  function renderAURow(props) {
    const { index, style } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => {window.location.href = "/profile/"+allUsers[index].id}}>
          <ListItemText sx={{userSelect: "none", paddingLeft: "2px"}} primary={allUsers[index].name} />
        </ListItemButton>
        <IconButton align="right" aria-label="delete" onClick={() => {handleUserDelete(allUsers[index].id)}}>
          <CloseIcon sx={{color: "red"}} />
        </IconButton>
      </ListItem>
    );
  }

  function renderAGRow(props) {
    const { index, style } = props;
  
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => {window.location.href = "/game/"+allGames[index].id}}>
          <ListItemText sx={{userSelect: "none", paddingLeft: "2px"}} primary={allGames[index].name} />
        </ListItemButton>
          <IconButton align="right" aria-label="delete" onClick={() => {handleGameDelete(allGames[index].id)}}>
            <CloseIcon sx={{color: "red"}} />
          </IconButton>
      </ListItem>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pendingUsersResponse, pendingGamesResponse, allGamesResponse, allUsersResponse] = await Promise.all([
          api.get('pending_users/'),
          api.get('pending_games/'),
          api.get('all_games/'),
          api.get('all_users/')
        ]);

        setPendingUsers(pendingUsersResponse.data);
        setPendingGames(pendingGamesResponse.data);
        setAllGames(allGamesResponse.data);
        setAllUsers(allUsersResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <RejectionReasoning open={open} handleClose={handleClose} gameId={selectedGame} />
      <Typography variant="h3" mt={3} mb={3} textAlign={"center"} fontWeight={800}>Admin Dashboard</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div style={{backgroundColor: '#274C6B', borderRadius: '20px', overflow: 'hidden'}}>
            <Typography textAlign={"center"} fontWeight={800}>Approve Users</Typography>
            <FixedSizeList
              height={400}
              width={"49vw"}
              itemSize={46}
              itemCount={pendingUsers.length}
              overscanCount={5}
              
            >
              {renderPURow}
            </FixedSizeList>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{backgroundColor: '#274C6B', borderRadius: '20px', overflow: 'hidden'}}>
          <Typography textAlign={"center"} fontWeight={800}>Approve Games</Typography>
          <FixedSizeList
            height={400}
            width={"49vw"}
            itemSize={46}
            itemCount={pendingGames.length}
            overscanCount={5}
          >
            {renderPGRow}
          </FixedSizeList>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{backgroundColor: '#274C6B', borderRadius: '20px', overflow: 'hidden'}}>
          <Typography textAlign={"center"} fontWeight={800}>All Users</Typography>
          <FixedSizeList
            height={400}
            width={"49vw"}
            itemSize={46}
            itemCount={allUsers.length}
            overscanCount={5}
          >
            {renderAURow}
          </FixedSizeList>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{backgroundColor: '#274C6B', borderRadius: '20px', overflow: 'hidden'}}>
          <Typography textAlign={"center"} fontWeight={800}>All Games</Typography>
          <FixedSizeList
            height={400}
            width={"49vw"}
            itemSize={46}
            itemCount={allGames.length}
            overscanCount={5}
          >
            {renderAGRow}
          </FixedSizeList>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;