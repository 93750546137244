import { Navigate } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../context/AuthProvider';
import api from "./api";

const AdminRoute = ({children, ...rest}) => {
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useContext(AuthContext);
    
    useEffect(() => {
        const getStatus = async () => {
            try {
                const response = await api.get('status_from_id/' + user.user_id);
                setStatus(response.data.status);
            } catch (error) {
                console.error('Error fetching status:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (status === '') {
            getStatus();
        }
    }, [status, user.user_id]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return status === 'admin' ? children : <Navigate to='/'/>;
};

export default AdminRoute;
