import Navbar from "../components/Navbar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BiotechIcon from "@mui/icons-material/Biotech";
import React, {useEffect, useState, useRef} from 'react';
import api from '../utils/api.js';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import "../styles/settings.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteConfirmation from "../components/DeleteConfirmation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Settings() {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [reloadWindow, setReloadWindow] = useState(false);
  const [formData, setFormData] = useState({});
  const [submission, setSubmission] = useState({});
  const [hideProfile, setHideProfile] = useState(false);
  const [hideScores, setHideScores] = useState(false);

  const handleRequestResearcher = async () => {
    await api.put('/update_user/', {status: "pending"}, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
    setReloadWindow(true);
  }

  const toggleSubmission = {
    hide_profile: hideProfile,
    hide_scores: hideScores
  };

  const handleProfileToggle = () => {
    setHideProfile(!hideProfile);
  };

  const handleScoresToggle = () => {
    setHideScores(!hideScores);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData };
    if (value.length > 0) {
      updatedFormData[name] = value;
    } else {
      delete updatedFormData[name];
    }
    setFormData(updatedFormData);
  };

  useEffect(() => {
    setSubmission(formData);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const acc = (value === 0);
    const formData = new FormData();
    if (acc) {
      Object.keys(submission).forEach((key) => {
        formData.append(key, submission[key]);
      });
    }
    try {
      const response = await api.put('/update_user/', acc ? formData : toggleSubmission, {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
      setUsernameTaken((response.data.message).includes('username'));
      setEmailTaken((response.data.message).includes('email'));
      setReloadWindow(!response.data.message.includes('username') && !response.data.message.includes('email'));
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  useEffect(() => {
    if (reloadWindow) {
      window.location.reload();
      setReloadWindow(false);
    }
  }, [reloadWindow]);

  const [open, setOpen] = useState(false);

  const openConfirmation = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [hovered, setHovered] = useState(true);
  const fileInputRef = useRef(null);

  const handleHover = () => {
    setHovered(false);
  };

  const handleMouseLeave = () => {
    setHovered(true);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const uploadedFile = files[0];
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(uploadedFile);
      let updatedFormData = { ...formData };
      updatedFormData['image'] = uploadedFile;
      setFormData(updatedFormData);
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await api.get('/current_user/', {
          headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }
        });
        setUser(response.data);
        setHideProfile(response.data.hide_profile);
        setHideScores(response.data.hide_scores);
        setAvatar(response.data.image)
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
      }

    if (!user) {
      getUserInfo();
    }
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <Box sx={{ flexGrow: 1, display: "flex", height: "100vh" }}>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            sx={{ color: "white" }}
            icon={<AccountBoxIcon />}
            label="Account"
          />
          <Tab
            sx={{ color: "white" }}
            icon={<AdminPanelSettingsIcon />}
            label="Privacy"
          />
          <Tab
            sx={{ color: "white" }}
            icon={<BiotechIcon />}
            label="Request Researcher Status"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Typography variant="h4" fontWeight={800}>
            Account Settings
          </Typography>
          <Divider light />

          <div className="label-container">
            <Typography variant="h6" className="label-name">
              Profile Picture:
            </Typography>
            <Box
              position="relative"
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              <Avatar
                sx={{ backgroundColor: "#274C6B", width: 130, height: 130, opacity: hovered ? 1 : 0.5 }}
              >
                {
                  hovered ? (
                    avatar ? (
                      <img src={avatar} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    ) : (
                      user.username[0]
                    )
                  ) : (
                    <UploadIcon />
                  )
                }
                <input
                  accept="image/*"
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Avatar>
            </Box>
          </div>

          <Divider light />
          <div className="label-container">
            <Typography variant="h6" className="label-name">
              Username:
            </Typography>
            <OutlinedInput
              required
              id="outlined-required-username"
              placeholder={user.username}
              name="username"
              error={usernameTaken}
              onChange={handleChange}
              sx={{
                "& input": {
                  color: "white", // Text color
                },
                "& fieldset": {
                  borderColor: "white", // Outline color
                },
                backgroundColor: "transparent", // Transparent background
                mb: 1, // Margin-bottom spacing
                p: 1, // Padding
              }}
            />
          </div>
          <Divider className="divider" />
          <div className="label-container">
            <Typography variant="h6" className="label-name">
              E-mail:
            </Typography>
            <OutlinedInput
              required
              id="outlined-required-email"
              placeholder={user.email}
              name="email"
              error={emailTaken}
              onChange={handleChange}
              sx={{
                "& input": {
                  color: "white", // Text color
                },
                "& fieldset": {
                  borderColor: "white", // Outline color
                },
                backgroundColor: "transparent", // Transparent background
                mb: 1, // Margin-bottom spacing
                p: 1, // Padding
              }}
            />
          </div>
          <Divider light />
          <div className="label-container">
            <Typography variant="h6" className="label-name">
              Password:
            </Typography>
            <OutlinedInput
              required
              id="outlined-required-password"
              placeholder="enter a password"
              type="password"
              name="password"
              onChange={handleChange}
              sx={{
                "& input": {
                  color: "white", // Text color
                },
                "& fieldset": {
                  borderColor: "white", // Outline color
                },
                backgroundColor: "transparent", // Transparent background
                mb: 1, // Margin-bottom spacing
                p: 1, // Padding
              }}
            />
          </div>
          <Divider light />

          <div className="button-container">
            <Button
              variant="contained"
              style={{ backgroundColor: "#386b2f", color: "#FFFFFF", mt: 2 }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>

            <Button
              variant="contained"
              style={{ backgroundColor: "#801f1f", color: "#FFFFFF" }}
              onClick={openConfirmation}
            >
              Delete Account
            </Button>
            <DeleteConfirmation open={open} handleClose={handleClose} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <FormGroup>
        <Stack spacing={3}>
          <Stack spacing={1} direction={"row"}>
            <Typography variant="h5" fontWeight={800}>
                Hide profile from other players:
            </Typography>
            <FormControlLabel control={<Switch checked={hideProfile} onChange={handleProfileToggle} />} />
          </Stack>
          <Stack spacing={1} direction={"row"}>
            <Typography variant="h5" fontWeight={800}>
                Hide scores from other players:
            </Typography>
            <FormControlLabel control={<Switch checked={hideScores} onChange={handleScoresToggle} />}  />
          </Stack>
          <div className="button-container">
            <Button
              variant="contained"
              style={{ backgroundColor: "#386b2f", color: "#FFFFFF", mt: 2 }}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </div>
        </Stack>

</FormGroup>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Stack>
        <Typography variant="h5" fontWeight={800}>
            Request researcher status
        </Typography>
        <Typography variant="h7" className="label-name">
        <Stack spacing={2.5}>
        <div style={{paddingLeft: '15px'}}>
          <li>Access all player scores</li>
          <li>Upload AI model behavior</li>
          <li>View and download all player data</li>
        </div>
        {(user.status === 'player') ? (
          <Button variant="contained" endIcon={<SendIcon />} style={{ backgroundColor: "#274C6B", color: "#FFFFFF", mt: 2 }} onClick={handleRequestResearcher}>
          Request
          </Button>
         ) : (
          (user.status === 'pending') ? (<Button variant="contained" disabled style={{ backgroundColor: "#193347", color: "#FFFFFF", mt: 2 }} >
          Request Already Sent
          </Button>) : (<Button variant="contained" disabled style={{ backgroundColor: "#193347", color: "#FFFFFF", mt: 2 }} >
          Already a Researcher
          </Button>)
         )
        }
        </Stack>
        </Typography>    
        </Stack>               
        </TabPanel>
      </Box>
    </div>
  );
}
