import React from 'react';
import '../styles/Documentation.css';
import Navbar from './Navbar';
import Footer from './Footer';

const Documentation = () => {
  return (
    <div>
      <Navbar />
      <div className="developer-docs-container">
        <div className="developer-docs">

          <h1>Game API Integration Guide</h1>
          <p> The aim of this document is to help familiarize you regarding using our API to send back score and game data back to Neuroarcade. We will focus on the game submission process and score formatting.</p>

          <h2>Overview</h2>
          <p>When you integrate your game on our platform, users can access your remotely on our platform. Our integration process involves appending a unique session token to your game's URL, which helps us identify the user being redirected and the game being played, due to the session token encoding both of those parameters in a secure manner.</p>

          <h2>Adding Your Game</h2>
          <p>Since you have been provided with Researcher privileges, you may now access the researcher page through the navigation bar and from there you can add games. Every game should ideally have a name, description, Dev name and a picture as well as an evaluation script if you would like to further process your score (optional); a URL is required. Once you create a game, it is provided a unique ID in our Database which is necessary for submitting user scores.</p>

          <h2>Session Token Workflow</h2>
          <p>following the creation of a game object on our platform, you must then ensure that you have integrated your game such that it can extract the query parameters generated when a user plays your game; will provide you with an example on how this may be done.
          </p>
          <ol>
            <li>
              <strong>Receiving the Session Token:</strong> As mentioned, when users access your game though neuroarcade, we append the URL provided for that game with a query parameter <code>session_token</code> Here is an example on how you can extract the session token using JavaScript:
              <pre><code>{`const urlParams = new URLSearchParams(window.location.search);\nconst sessionToken = urlParams.get('session_token');`}</code></pre>
            </li>
            <li>
              <strong>Handling the Session Token:</strong> It is vital that you quickly extract the session token as it has a short expiration window for security reasons.
            </li>
            <li>
              <strong>Submitting Scores:</strong> To submit scores, include the session token in the Authorization header as a Bearer token. Your submission is responsible for identifying whether the score is tied to an AI or Human player (denoted as "AI" or "HU").
              <pre><code>{`fetch('http://127.0.0.1:8000/api/game/score-submit/', {\n  method: 'POST',\n  headers: {\n    'Content-Type': 'application/json',\n    'Authorization': \`Bearer \${sessionToken}\`,\n  },\n  body: JSON.stringify({\n    player_type: 'HU', // Or 'AI'\n    score: {\n      points: 100,\n      time: "120s",\n      // add more to score in this same format\n    },\n  }),\n})\n.then(response => response.json())\n.then(data => console.log(data))\n.catch(error => console.error('Error:', error));`}</code></pre>
              Please note that this is only an example, and you may choose to implement it differently, however the format for the request should remain the same. You must include the  <code>session_token</code> by extracting it from the URL. You should also provide the <code>player_type</code> as AI or Human as noted above. The <code>score</code> is very customisable however it must be in a JSON format explicitly.
            </li>
          </ol>

          <h2 className="section-heading">Creating Evaluation Scripts</h2>
          <p className="section-description">
            Evaluation scripts offer a powerful and versatile way to proccess the score data send back to us via the data endpoint defined above. Evaluation scripts need to be designed in a particular  which is relative to your score submission.
             Meaning that each script you upload needs to reflect the metrics you defined in the score JSON payload.
          </p>

          <h3 className="subsection-heading">Designing Your Evaluation Script</h3>
          <p className="subsection-description">
            Bellow is a provided demo, with a example score submission and its counter-part evaluation script:
          </p>
          <div className="code-block">
            <p>Example score submission with 3 metrics:</p>
          <pre><code>{`fetch('http://127.0.0.1:8000/api/game/score-submit/', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer YOUR_TOKEN', 
  },
  body: JSON.stringify({
    player_type: 'AI',
    score: {
      points: 100,
      levels_completed: 2,
      bonus_items: 3,
    },
  }),
})
.then(response => response.json())
.then(data => console.log(data))
.catch(error => console.error('Error:', error));
`}</code></pre>

</div>
<div className="code-block">
<p>Example evaluation script with 3 metrics:</p>
<pre><code>{
`
import sys   # allowing access to some variables used by the Python
import json  # decoding of JSON objects

def evaluate_score(input_json):  # Defines a function to evaluate scores, taking a JSON string as input.
    data = json.loads(input_json)  # Parses the input JSON string into a Python dictionary.

    if 'score' in data:
        score_data = data['score']  # Retrieves the value associated with the 'score' key.
        
        if data.get('player_type') == 'AI':  # if player_type is AI
            score_data['points'] += 10  # increments points by 10
    
    return data
if __name__ == "__main__":  # Checks if the script is being run as the main program.
    input_json = sys.argv[1]  # Takes the first command-line argument (after the script name) as the JSON input.
    result = evaluate_score(input_json)
    print(json.dumps(result.get('score')))  # Prints the whats in the score in JSON.`}</code></pre>
          </div>
          <p>This is the original data sent:
            <pre>{"{"}"points": 100, "levels_completed": 2, "bonus_items": 3{"}"}</pre>
             and this is the processed data:
             <pre>{"{"}"points": 110, "levels_completed": 2, "bonus_items": 3{"}"}</pre> </p>
          <p className="integration-instructions">
            Your script needs to match and reflect your score submission in implementation; the code sniplets above demonstraiting the usage of an evaluation script with a custom score submission is only an example and your script will vary depending on your JSON payload.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Documentation;
