import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Avatar from '@mui/material/Avatar';
import "../styles/profile.css";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { isBrowser } from "react-device-detect";
import { useParams } from 'react-router-dom';
import api from "../utils/api";

let widths = [];

if (isBrowser) {
  widths = [200, 120, 120, 400, 70, 30];
} else {
  widths = [100, 50, 50, 50, 80, 20];
}

const columns = [
  {
    width: widths[0],
    label: 'Game',
    dataKey: 'game_name',
  },
  {
    width: widths[1],
    label: 'View Score',
    dataKey: 'game_id',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} sx={{ backgroundColor: '#1D3850' }} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: '#274C6B',
            color: 'white',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

let boxSize;

if (isBrowser) {
    boxSize = '600px';
}
else {
    boxSize = '300px';
}

const Profile = () => {
  const [user, setUser] = useState('');
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { profileId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfoResponse = await api.get('/user_from_id/' + profileId, {
          headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }
        });
        setUser(userInfoResponse.data);

        const scoresResponse = await api.get('/scores_from_user_id/' + profileId, {
          headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }
        });
        setScores(scoresResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        window.location.replace("/404");
      }
    };

    fetchData();
  }, [profileId]);

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        <TableCell
          sx={{
            backgroundColor: '#274C6B',
            color: 'white',
          }}
          key={columns[0].dataKey}
          align='left'
        >
          {row[columns[0].dataKey]}
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: '#274C6B',
            color: 'white',
          }}
          key={columns[1].dataKey}
          align='left'
        >
          <Button sx={{color: 'white', backgroundColor: "#2A548B"}} variant="contained" onClick={() => { window.location.href = `/game/${row[columns[1].dataKey]}/${user.username}` }}>Go to Game</Button>
        </TableCell>
      </React.Fragment>
    );
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className={"profile"}>
        <Avatar
          src={user.image}
          sx={{ width: 150, height: 150 }}
        />
        <h1>
          {user.username}
        </h1>
        <div className={"accountInfo"}>
          <p>
            Account Status: {(user.status === 'pending') ? ('Player') : ((user.status).charAt(0).toUpperCase() + user.status.slice(1))}
          </p>
          <p>
            Date Joined: {(user.date_joined).substring(0,10).split("-").reverse().join("-")}
          </p>
        </div>
          <div className={"accountStats"}>
          {scores.message === "user has no scores" ? <h2>No Scores</h2> :
            <div className={"container"} style={{minHeight: boxSize, minWidth: boxSize, marginBottom: '20px'}}>
              <Paper id={"scores"} style={{height: '100%', width: '100%', transform: 'translateY(0px)'}}>
                <TableVirtuoso
                  data={scores.map((score, index) => ({id: index, game_name: score.game_name, game_id: score.game_id}))}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={(index, row) => rowContent(index, row)}
                />
              </Paper>
            </div>
          }
        </div>

      </div>
    </div>
  );
}

export default Profile;
