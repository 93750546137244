import React, { useState, useEffect } from 'react';
import api from "../utils/api";
import '../styles/home.css';
import { useNavigate } from 'react-router-dom';

function GameCard({ game, onCardClick }) {
  return (
    <div onClick={onCardClick}>
      <div style={{backgroundColor: '#274C6B', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', padding: '40px', borderRadius: '15px'}}>
        <img className="gameimg" src={game.image} alt="Game"></img>
        <div className="title">{game.name}</div>
      </div>
    </div>
  );
}

function Games() {
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getGames = async () => {
      let response;
      let approvedGames = [];
      try {
        response = await api.get('all_games/');
        for (const game of response.data) {
          if (game.status === 'approved') {
            approvedGames.push(game)
          }
        }
        setGames(approvedGames);
        setFilteredGames(approvedGames); // Set filteredGames to all games initially
      } catch (error) {
        console.error('Error fetching game data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    getGames();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = games.filter(game => {
      return game.name.toLowerCase().includes(query);
    });
    setFilteredGames(filtered);
    setSearchQuery(query);
  };

  const navigate = useNavigate();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search games..."
          value={searchQuery}
          onChange={handleSearch}
          className="search-bar"
        />
      </div>
      <div className="gamesContainer">
        <div className="games">
          {filteredGames.length > 0 ? 
            filteredGames.map((game, index) => (
              <GameCard
                key={index}
                game={{
                  ...game,
                }}
                onCardClick={() => navigate('/game/'+game.id)}
              />
            )) : 
            <div>No games found</div>
          }
        </div>
      </div>
    </div>
  );
}

export default Games;
