import React, { useState, useEffect  } from 'react';
import api from "../utils/api";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import CreateGame from "../components/CreateGame";
import EditGame from "../components/EditGame";
import '../styles/researcher.css';

const Researcher = () => {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedGame, setSelectedGame] = useState(-1);
  const [isDenied, setIsDenied] = useState(false);

  const openGameCreator = () => {
    setOpen(true);
  };

  const handleClose = (shouldReload) => {
    setOpen(false);
    if (shouldReload) {
      window.location.reload();
    }
  };

  const openGameEditor = (gameId, status) => {
    setSelectedGame(gameId);
    setOpen2(true);
    setIsDenied(status === 'denied');
  };

  const handleClose2 = (shouldReload) => {
    setOpen2(false);
    if (shouldReload) {
      window.location.reload();
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const fetchGames = async () => {
    try {
      const response = await api.get('/my-games/');
      setGames(response.data.map(game => ({
        ...game,
        evaluation_script_name: game.evaluation_script ? game.evaluation_script.split('/').pop() : ''
      })));
    } catch (error) {
      console.error('Failed to fetch games:', error);
    }
  };

  const deleteGame = async (id) => {
    try {
      await api.delete(`/games/${id}/delete`);
      fetchGames();
    } catch (error) {
      console.error('Failed to delete the game:', error);
    }
  };

  const handleGameLinkClick = async (gameId) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/game/session/initiate/${gameId}/`);
      const redirectUrl = response.data.redirect_url;
      if (redirectUrl) {
          window.open(redirectUrl, 'blank');
      } else {
          console.error('Token or redirect URL is undefined.');
      }
    } catch (error) {
      console.error('Failed to initiate game session:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="dev-page-container">
      <Navbar />
      <div style={{display:"flex", width: "100%", alignItems: "center", justifyContent:"center", flexDirection: "column"}}>
        <h1>Researcher Dashboard</h1>
        <button className="dev-page-submit-btn" onClick={openGameCreator}>Add Game</button>
      </div>
      <EditGame open={open2} handleClose={handleClose2} gameId={selectedGame} isDenied={isDenied} /> {/* Pass isDenied as prop */}
      <CreateGame open={open} handleClose={handleClose} />
      <h3>My Submitted Games</h3>
      <div className="dev-page-table-container">
        <table>
          <thead>
            <tr>
              <th>Game Name</th>
              <th>Game URL</th>
              <th>Description</th>
              <th>Image</th>
              <th>Evaluation Script</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <tr key={game.id}>
                <td>{game.game_name}</td>
                <td>
                  <button 
                    onClick={() => handleGameLinkClick(game.id, game.game_url)} 
                    disabled={isLoading} 
                    className="dev-page-game-link">
                    {isLoading ? 'Loading...' : 'Play'}
                  </button>
                </td>
                <td>{game.description ? 'Yes' : 'No'}</td>
                <td>{game.image ? 'Yes' : 'No'}</td>
                <td>{game.evaluation_script_name || "No"}</td>
                {/* Render status here */}
                <td>{game.status}</td>
                <td>
                  {/* Conditionally render edit button based on game status */}
                  <button onClick={() => openGameEditor(game.id, game.status)} className="dev-page-edit-btn">
                    Edit
                  </button>
                  <button onClick={() => deleteGame(game.id)} className="dev-page-delete-btn">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default Researcher;
