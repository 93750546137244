import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Radar } from 'react-chartjs-2';

const CompareChart = () => {

  const labels = ['Precision', 'Reactions', 'Consistency', 'Adaptability', 'Patterns'];
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Model 1',
        data: [90, 70, 50, 90, 20],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132, 0.2)',
      },
      {
        label: 'Model 2',
        data: [20, 10, 60, 80, 100],
        borderColor: 'rgb(51,255,0)',
        backgroundColor: 'rgb(51,255,0, 0.2)',
      },
    ],
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90vw', height: '70vh', transform: "translateX(5%)", backgroundColor: '#274C6A' }}>
      <Radar
        data={data}
        options={{
          plugins: {
            legend: {
              display: true,
              labels: {
                color: 'rgb(255,255,255)'
              }
            }
          },
          scales: {
            r: {
              pointLabels: {
                color: 'rgb(255,255,255)',
              },
              beginAtZero: true,
              min: 0,
              max: 100,
              angleLines: {
                color: 'rgb(255,255,255)',
              },
              grid: {
                color: 'rgb(255,255,255)',
              },
            },
          },
        }}
      />
    </div>
  );
};

class Compare extends Component {

  render() {
    return (
      <div>
        <Navbar />
        <h1 style={{ fontSize: '35px', color: 'white', textAlign: 'center' }}>
          Model 1's Score vs Model 2's Score
        </h1>
        <CompareChart />
        <Button variant="contained" style={{ marginTop: '20px', marginBottom: '20px', left: '50%', transform: 'translateX(-50%)', backgroundColor: "#2A548B" }} endIcon={<ArrowBack />} onClick={() => window.history.go(-1)}>
          Back
        </Button>
      </div>
    );
  }
}

export default Compare;