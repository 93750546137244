import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Psychology from "@mui/icons-material/PsychologyRounded";
import { Link } from "react-router-dom";
import {BrowserView, MobileView} from "react-device-detect";
import { Login } from "./Login";
import AuthContext from '../context/AuthProvider';
import api from "../utils/api";

function Navbar() {

  let { user } = useContext(AuthContext);
  let [pfp, setPfp] = useState('');

  const getUserInfo = async () => {
    if (user) {
      let response;
      try {
        response = await api.get('current_user/', {
          headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }
        });
        setPfp(response.data.image);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  getUserInfo();

  const settings = user ? (
    (user.status === 'admin') ? 
      ([
        { label: 'Profile', link: '/profile/' + user.user_id },
        { label: 'Dashboard', link: '/dashboard' },
        { label: 'Settings', link: '/settings' },
        { label: 'Researcher', link: '/researcher' },
        { label: 'API Docs', link: '/documentation' },
      ]) : 
    (user.status === 'researcher' ? 
      ([
        { label: 'Profile', link: '/profile/' + user.user_id },
        { label: 'Settings', link: '/settings' },
        { label: 'Researcher', link: '/researcher' },
        { label: 'API Docs', link: '/documentation' },
      ]) : 
      ([
        { label: 'Profile', link: '/profile/' + user.user_id },
        { label: 'Settings', link: '/settings' },
      ])
    )
  ) : ([]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickUniLogo = () => {
    const link = 'https://www.gla.ac.uk/schools/psychologyneuroscience/research/ccni/';
    window.open(link, '_blank');
  };

  const handleClickNeuroLogo = () => {
    window.location.href = "/";
  };

return (
    <AppBar position="static" sx={{ bgcolor:"#274C6A" }}>
      <Container maxWidth="100%">
        <Toolbar disableGutters>

            <img src="/logo.png" alt="University of Glasgow" style={{width: '100px', cursor: 'pointer', zIndex: '2'}} onClick={handleClickUniLogo} />

            <div style={{position: 'absolute', width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                <MobileView>
                    <Psychology sx={{position:'relative', transform: 'scale(2)', cursor: 'pointer'}} onClick={handleClickNeuroLogo} />
                </MobileView>
                <BrowserView>
                    <Psychology sx={{position:'relative', transform: 'translate(0,3px)', cursor: 'pointer'}} onClick={handleClickNeuroLogo} />
                  <Typography
                    variant="h5"
                    component="a"
                    href="/"
                    sx={{
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                  >
                    NEUROARCADE
                  </Typography>
                </BrowserView>
            </div>

            <div style={{position:'absolute', right:'0'}}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src={pfp}>
                  </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={() => {
                  window.location.href = setting.link;
                  handleCloseUserMenu();
                }}>
                  <Link to={setting.link} style={{ textDecoration: 'none' }}>
                    <Typography color="black" textAlign="center">{setting.label}</Typography>
                  </Link>
                </MenuItem>
              ))}
              <Login handleCloseUserMenu={handleCloseUserMenu} />
            </Menu>
            </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default React.memo(Navbar);
