import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from "@mui/material/Stack";
import api from '../utils/api.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#274C6A',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const handleDelete = async () => {
  try {
    await api.delete('/delete_user/', {headers: { key: JSON.parse(localStorage.getItem('authTokens')).access }});
    localStorage.removeItem('authTokens')
    window.location.reload();
  } catch (error) {
    console.error('Error deleting user account:', error);
  }
};

const DeleteConfirmation = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" fontWeight={800} component="h2">
            Are You Sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Account deletion is an irreversible process. Choose the data associated with your account that you wish to have deleted.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Note: Deleting games will have no effect if you haven't added any
          </Typography>
          <FormGroup sx={{mt:1, mb:1}}>
            <FormControlLabel control={<Checkbox sx={{color:'white'}} defaultChecked />} label="Scores" />
            <FormControlLabel control={<Checkbox sx={{color:'white'}}  defaultChecked />} label="Games" />
          </FormGroup>
          <Stack>
            <Button variant="contained" style={{ backgroundColor: "#801f1f", color: "#FFFFFF", marginBottom: 7, borderRadius: 12 }} onClick={handleDelete}>Confirm Deletion</Button>
            <Button variant="outlined" style={{ borderColor:"#1C9ACA",color:"#1C9ACA", borderRadius: 12}} onClick={handleClose}>Cancel</Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteConfirmation;
