import React, { Component } from 'react';
import Anime from 'animejs';
import '../styles/infoheader.css';

class InfoHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
  }

  componentDidMount() {
    Anime.timeline({ easing: 'easeOutExpo' })
      .add({
        targets: '.Header p',
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 500,
      })
      .add({
        targets: '.textBody p',
        translateY: [100, 0],
        opacity: [0, 1],
        delay: Anime.stagger(100, { start: 100 }),
        duration: 1200,
      }, '-=400')
      .add({
        targets: '.learnMoreButtonContainer',
        opacity: [0, 1],
        duration: 2200,
        begin: () => {
          this.setState({ showButton: true });
        }
      }, '-=800');
  }

  render() {
    return (
      <div className='ContentContainer'>
        <div className='Header'>
          <p>NeuroArcade</p>
        </div>
        <div className='textBody'>
          <p>Welcome to NeuroArcade, select one of the following games to begin the benchmark</p>
        </div>
        <div className='learnMoreButtonContainer'>
          {this.state.showButton && (
            <a href="/about" className="learnMoreButton">Learn More</a>
          )}
        </div>
      </div>
    );
  }
}
export default InfoHeader;
