import React, {Component} from 'react';
import Navbar from "../components/Navbar";
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';

class Play extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div style={{ display: 'flex', position: 'absolute', width: '100%', height: '90%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <h1 style={{ fontSize: '35px', color: 'white', width: '100%', textAlign: 'center' }}>
            Game Name
          </h1>
          <div style={{ flex: '1', width: '80vw', position: 'relative', backgroundColor: '#274C6A' }}>
            <img src={"/images/unitylogo.png"} alt="Play Button" style={{ width: '50%', height: '100%', objectFit: 'contain', position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)', opacity: '0.5' }} />
          </div>
          <Button variant="contained" style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: "#2A548B" }} endIcon={<ArrowBack />} onClick={() => window.history.go(-1)}>
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default Play;