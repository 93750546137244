import React, {Component} from 'react';
import Navbar from '../components/Navbar';
import InfoHeader from '../components/InfoHeader';
import Games from '../components/Games';
import Footer from '../components/Footer';

class Home extends Component {
  state = {
    data: []
  };

  render() {
    return (
      <div>
        <Navbar />
        <InfoHeader />
        <Games />
        {this.state.data.map(item => (
          <div key={item.name}>
            <h1>{item.name}</h1>
            <span>{item.age}</span>
          </div>
        ))}
        <Footer />
      </div>
    );
  }
}

export default Home;
