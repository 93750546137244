import React, { Component } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import "../styles/about.css";
import Button from "@mui/material/Button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

class About extends Component {
 
    componentDidMount() {
        gsap.registerPlugin(ScrollTrigger);
    
        gsap.to(".aboutPage", {
            duration: 0.5,
            opacity: 1,
        });
    
        const sections = gsap.utils.toArray('.customSection');
        sections.forEach((section) => {
            let startOffset = "top center";
            let endOffset = "bottom center";
    
            ScrollTrigger.create({
                trigger: section,
                start: startOffset,
                end: endOffset,
                onEnter: () => this.setActiveSection(section),
                onLeaveBack: () => this.setActiveSection(section, true),
                onEnterBack: () => this.setActiveSection(section),
                onLeave: () => this.setActiveSection(section, true),
                markers: false,
            });
        });
    }
    
    setActiveSection(section, reset = false) {
        const sections = gsap.utils.toArray('.customSection');
        sections.forEach(s => {
            s.classList.remove('active');
        });
    
        if (!reset) {
            section.classList.add('active');
        }
    }

    renderSection(title, content, className) {
        return (
            <Parallax className={`customSection ${className}`} y={[100, -100]} tagOuter="figure">
                <div className="content">
                    <h2>{title}</h2>
                    <p>{content}</p>
                </div>
            </Parallax>
        );
    }

    render() {
        return (
            <ParallaxProvider>
                <Navbar/>
                <div className="aboutPage">  
                    {this.renderSection("The Project", "Neuroarcade is made in colaboration with The Centre for Cognitive Neuroimaging at the University of Glasgow, aiming to provide a platform for researchers and enthusiasts alike to test their AI models in video games.", "aboutSection")}
                    {this.renderSection("Our Vision", "Using a token based system, we aim to provide key insights on the strenghts and weaknesess of each player; those insights can be further used to improve AI models or help real players improve their strategy.", "visionSection")}
                    {this.renderSection("Our Team", "Our team consists of level 3 CompSci students and this project is a combination of our efforts to meet our expected goals, we have made the codebase open-source and available such that this project can be altered to suit a different criteria.", "teamSection")}
                    {this.renderSection("Our Work", "Explore the game library and go head to head to compete with other humans or AI models !", "workSection")}
                    {this.renderSection("Contact Us", "If you have any inquiries feel free to contact us, the contact information and adresses have been stated.", "contactSection")}
                    
                    
                    <div className="backButtonContainer">
                        <Button variant="contained" onClick={() => window.history.go(-1)}>
                            <ArrowBack />
                            Back
                        </Button>
                    </div>
                </div>
                <Footer/>
            </ParallaxProvider>
        );
    }
}

export default About;
