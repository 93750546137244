import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <Box sx={{
            bgcolor: "#274C6A",
            color: "white",
            py: 2,
            width: "100%",
        }}>
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={6}>
                        <Typography variant="h6" color="inherit" sx={{ fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.1rem', mb: 1 }}>
                            Follow Us
                        </Typography>
                        <Link href="http://facebook.com" color="inherit" sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none' }}>
                            <FacebookIcon sx={{ mr: 1 }}/> Facebook
                        </Link>
                        <Link href="http://twitter.com" color="inherit" sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none' }}>
                            <TwitterIcon sx={{ mr: 1 }}/> Twitter
                        </Link>
                        <Link href="http://instagram.com" color="inherit" sx={{ display: 'flex', alignItems: 'center', mb: 1, textDecoration: 'none' }}>
                            <InstagramIcon sx={{ mr: 1 }}/> Instagram
                        </Link>
                        <Link href="http://linkedin.com" color="inherit" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <LinkedInIcon sx={{ mr: 1 }}/> LinkedIn
                        </Link>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="inherit" sx={{ fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.1rem', mb: 1 }}>
                            Quick Links
                        </Typography>
                        <Link href="/settings" color="inherit" sx={{ display: 'block', mb: 1, textDecoration: 'none' }}>Settings</Link>
                        <Link href="/about" color="inherit" sx={{ display: 'block', mb: 1, textDecoration: 'none' }}>About</Link>
                        <Link href="/profile" color="inherit" sx={{ display: 'block', textDecoration: 'none' }}>Profile</Link>
                    </Grid>
                </Grid>
                <Typography variant="body2" color="inherit" sx={{ mt: 4, textAlign: 'center', fontFamily: 'monospace' }}>
                    © NeuroArcade 2024
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
