import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./routes/home";
import About from "./routes/about";
import NotFound from "./NotFound";
import Profile from "./routes/profile";
import Settings from "./routes/settings";
import Game from "./routes/game";
import Play from "./routes/play";
import Dashboard from "./routes/dashboard";
import Compare from "./routes/compare";
import { AuthProvider } from "./context/AuthProvider";
import PrivateRoute from './utils/PrivateRoute';
import AdminRoute from "./utils/AdminRoute";
import Documentation from './components/Documentation';
import Researcher from './components/Researcher';
import ResearcherRoute from './utils/ResearcherRoute';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "researcher",
    element: <PrivateRoute><ResearcherRoute><Researcher/></ResearcherRoute></PrivateRoute>,
  },
  {
    path: "documentation",
    element:<PrivateRoute><ResearcherRoute><Documentation/></ResearcherRoute></PrivateRoute>
  },
  {
    path: "profile/:profileId",
    element: <PrivateRoute><Profile /></PrivateRoute>,
  },
  {
    path: "settings",
    element: <PrivateRoute><Settings /></PrivateRoute>,
  },
  {
    path: "game/:gameId",
    element: <Game />,
  },
  {
    path: "game/:gameId/:playerToSearchFor",
    element: <Game />,
  },
  {
    path: "play/:gameId",
    element: <Play />
  },
  {
    path: "compare/*",
    element: <Compare />
  },
  {
    path: "dashboard/",
    element: <PrivateRoute><AdminRoute><Dashboard /></AdminRoute></PrivateRoute>,
  },
  {
    path: "404",
    element: <NotFound />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
