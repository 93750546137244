import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {

    let [loading, setLoading] = useState(true)
    let [loginError, setLoginError] = useState(false)
    let [passwordMismatch, setPasswordMismatch] = useState(false)
    let [usernameTaken, setUsernameTaken] = useState(false)
    let [emailTaken, setEmailTaken] = useState(false)

    let [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null))
    let [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null))

    let registerUser = async (e, email, username, password, password2) => {
        const response = await fetch("https://sh18.ccni-socs-tp3.xyz/api/register/", {
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({
                email, username, password, password2
            })
        })
        let data = await response.json();
        if(response.status === 201){
            setUsernameTaken(false);
            setEmailTaken(false);
            loginUser(e);
        } else {
            if (data['email']) {
                setEmailTaken(true);
            }
            else {
                setEmailTaken(false);
            }
            if (data['username']) {
                setUsernameTaken(true);
            }
            else {
                setUsernameTaken(false);
            }
            if (password !== password2) {
                setPasswordMismatch(true);
                return null;
            }
            setPasswordMismatch(false);
        }
    }

    let loginUser = async (e) => {
        e.preventDefault();
        if (!e.target.username.value|| !e.target.password.value) {
            return;
        }
        const response = await fetch('https://sh18.ccni-socs-tp3.xyz/api/token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: e.target.username.value, password: e.target.password.value })
        });

        let data = await response.json();

        if(data['detail'] === undefined){
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            setLoginError(false);
            window.location.reload();
        } else {
            setLoginError(true);
        }
    }

    let logoutUser = (e) => {
        localStorage.removeItem('authTokens')
        setAuthTokens(null)
        setUser(null)
        window.location.reload();
    }

    let contextData = {
        user: user,
        loggedInUser: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        registerUser: registerUser,
        loginError: loginError,
        passwordMismatch: passwordMismatch,
        usernameTaken: usernameTaken,
        emailTaken: emailTaken,
    }

    const updateToken = async () => {
        const response = await fetch('https://sh18.ccni-socs-tp3.xyz/8000/api/token/refresh/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body:JSON.stringify({refresh:authTokens?.refresh})
        })

        const data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens',JSON.stringify(data))
        } else {
            logoutUser()
        }

        if(loading){
            setLoading(false)
        }
    }

    useEffect(()=>{

        const REFRESH_INTERVAL = 1000 * 60 * 240// 3 minutes
        let interval = setInterval(()=>{
            if(authTokens){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authTokens])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}
