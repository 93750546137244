import Navbar from "./components/Navbar";
import "./styles/notfound.css";
import { useTrail, a } from '@react-spring/web';
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowBack from "@mui/icons-material/ArrowBack";
import {BrowserView, MobileView} from "react-device-detect";

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 6, tension: 1000, friction: 300 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={"trailsText"} style={style}>
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};

export default function ErrorPage() {
  const [open] = useState(true);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div>
      <Navbar />
      <img id="ai" src={"./images/AI_Head.jpg"} alt="AI" />
      <img id="human" src={"./images/Human_Head.jpeg"} alt="Human" />
      <BrowserView>
        <div className={"msg"}>
          <Trail open={open}>
            <span>Error 404:</span>
            <span>Page Not Found</span>
            <Button variant="contained" style={{ backgroundColor: "#2A548B" }} endIcon={<ArrowBack />} onClick={() => window.history.go(-1)}>
              Back
            </Button>
          </Trail>
        </div>
      </BrowserView>
      <MobileView>
        <div className={"msg-mobile"}>
          <Trail open={open}>
            <span>Error 404:</span>
            <span>Page Not Found</span>
            <Button variant="contained" style={{ backgroundColor: "#2A548B" }} endIcon={<ArrowBack />} onClick={() => window.history.go(-1)}>
              Back
            </Button>
          </Trail>
        </div>
      </MobileView>

    </div>
  );
};
