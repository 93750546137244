import axios from 'axios';

const api = axios.create({
  baseURL: "https://sh18.ccni-socs-tp3.xyz/api/",
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : null;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default api;
