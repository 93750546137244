import { Navigate } from 'react-router-dom'
import React, { useContext } from 'react';
import AuthContext from '../context/AuthProvider';

const PrivateRoute = ({children, ...rest}) => {
    const { user } = useContext(AuthContext);

    return !user ? <Navigate to='/'/> : children;
}

export default PrivateRoute;